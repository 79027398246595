<template>
  <div>
    <!-- <div class="breadcrumbs-area">
      <h3 class="red--text">School Setup</h3>
      <ul>
        <li>
          <a href="index.html">Subjects</a>
        </li>
        <li>All Subjects</li>
      </ul>
    </div>
    <div></div>
    <div> -->
    <DataTableComponent :table="table"></DataTableComponent>
    <!-- </div> -->
  </div>
</template>

<script>
import DataTableComponent from "../../components/DataTableComponent";
export default {
  data() {
    return {
      table: {
        modal: "Subject",
        path: "subjects",
        headers: [
          {
            text: this.$i18n.t("Name"),
            value: "en.name",
          },
          {
            text: this.$i18n.t("Arabic Name"),
            value: "ar.name",
          },
          {
            text: this.$i18n.t("Subject Type"),
            value: "subject_type_name",
          },
          {
            text: this.$i18n.t("Actions"),
            value: "actions",
            sortable: false,
            width: "12%",
          },
        ],
        title: this.$i18n.t("Subjects"),
        apiEndPoints: {
          list: "subjects",
          create: "subjects/store",
          edit: "subjects/update",
          delete: "subjects/destroy",
        },
        editedItem: {
          en: {
            name: "",
          },
          ar: {
            name: "",
          },
          subject_type_id: "",
          subject_type_name: "",
        },
        permissions: {
          list: "list-subjects",
          add: "add-subjects",
          edit: "edit-subjects",
          delete: "delete-subjects",
        },
      },
    };
  },
  methods: {},
  components: {
    DataTableComponent,
  },
  mounted() {},
};
</script>

<style></style>
